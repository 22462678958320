

import React, { useState, useEffect } from 'react';

const ModalBook = ({ isVisible, onClose, children }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    if (!isVisible) return null;

    const handleClose = (e) => {
        if (e.target.id == "wrapper") onClose();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form submitted:', formData);
    };
    return (
        <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center' onClick={handleClose} id="wrapper">
            <div className='w-[900px]  flex flex-col h-auto' >

                <div className='modal-body'>
                    <button className='text-black text-3xl place-self-end close-btn' onClick={() => onClose()}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                    </button>
                    <div className='card-contact'>

                        <div className='inline-block'>
                            <h4 className='bg-text'>
                                Drop us a line
                            </h4>
                        </div>
                        <div>
                            <form action="mail.php" method= "POST" className="mt-2 ">
                                <div className='mt-3' >
                                    <label className="block mb-1  ">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder='Enter Name'
                                    />

                                </div>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4  mt-3'>
                                    <div>
                                        <label className="block mb-1   ">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="w-full p-2 border rounded-md"
                                            placeholder='Enter Email'

                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-1   ">Phone</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            className="w-full p-2 border rounded-md"
                                            placeholder='Enter Phone'

                                        />
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <label className="block mb-1 ">Message</label>
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder='Enter Message'

                                    ></textarea>
                                </div>

                                <div className='mt-3'>

                                    <button className="btn btn-primary"
                                        onClick={handleSubmit}> Submit</button>
                                </div>
                            </form>
                        </div>






                    </div>
                </div>

            </div>

        </div>
    );
};

export default ModalBook;
