import React, { useState, useEffect } from 'react';

export const Accordion = (props) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const rotateArrow = (index) => {
        return activeIndex === index ? 'rotate' : '';
      };
    return (
        <div>

            <div className="accordion-fq">
                {props.item.map((itemAcc, index) => (
                    <div key={index} className="accordion-item">
                        <div
                            className={`accordion-header-fq flex ${activeIndex === index ? 'accordion-active' : ''}`}
                            onClick={() => handleClick(index)}
                        >
                            <h3 className="text-lg font-semibold">{itemAcc.title}</h3>
                            <span className={`ml-2 transition-transform duration-200 transform  ${rotateArrow(index)}`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                </svg>
                            </span>
                        </div>
                        {activeIndex === index && (
                            <div className="accordion-content"><p>{itemAcc.content}</p></div>
                        )}
                    </div>
                ))}
            </div>



        </div>
    )
}
