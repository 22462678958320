import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";


import { Footer } from '../components/Footer';

import "./pages.css";

import bgImg from '../images/bg-contact.mp4';
import phone from '../images/icon/phone-call.png';
import mail from '../images/icon/mail.png';
import marker from '../images/icon/marker.png'



export const ContactUs = () => {
    const textToType = 'Contact our team.';
    const [displayText, setDisplayText] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form submitted:', formData);
    };
 
    useEffect(() => {
        let currentIndex = 0;
        const typingInterval = setInterval(() => {
            if (currentIndex <= textToType.length) {
                setDisplayText(textToType.slice(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(typingInterval);
            }
        }, 100); // Adjust typing speed here (milliseconds)

        return () => clearInterval(typingInterval);
    }, []);
    return (
        <section className='contact-page' id='Contact'>
            <section className='banner'>
                <div className="video-container  sub-banner-video">
                    <video autoplay loop muted className="object-cover" autoPlay>
                        <source src={bgImg} type="video/mp4" />
                    </video>
                    <div className='black-overlay'></div>
                </div>
                <div className='bg-body container max-auto px-4 lg:px-20  width-max'>
                    <div className="grid lg:grid-cols-1 gird-cols-1 grid-flow-col text-center">
                        <div className="row-span-2">
                            <motion.h1
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1 }}
                            >
                                {displayText}
                            </motion.h1>

                        </div>
                    </div>
                </div>
            </section>
            <section className=' p-t-b px-4 lg:px-20'>
                <div className='grid lg:grid-cols-2 contact-body'>
                    <div>
                        <h1>Get in touch</h1>
                        <p>
                        At Doorto360, we're driven by a passion for innovation and a commitment to excellence. For the
 past 5 years, we've been at the forefront of redefining the space selling experience. We aim to
 bridge the gap between imagination and reality, creating virtual environments that captivate and
 inspire. With every project, we aim to bring tangible value to your business and services, turning
 dreams into virtual realities.                            </p>
                        <div className='info'>
                            <div className='grid lg:grid-cols-2 '>
                                <div className='flex  gap-2 items-center'>
                                    <div className='icon-sm'>
                                        <img src={phone} alt="" />
                                    </div>
                                    <h6>+91 9778305495</h6>
                                </div>
                                <div className='flex  gap-2 items-center'>
                                    <div className='icon-sm'>
                                        <img src={mail} alt="" />
                                    </div>
                                    <h6>contact@doorto360.com</h6>
                                </div>
                            </div>
                            <div className='flex  gap-2 items-center mt-4'>
                                <div className='icon-sm'>
                                    <img src={marker} alt="" />
                                </div>
                                <h6>We are (VR) where you are! We work remotely across Kerala</h6>
                            </div>
                        </div>

                    </div>

                    <div className='card-contact'>

                       <div className='inline-block'>
                       <h4>
                            GET IN TOUCH WITH US

                        </h4>
                       </div>
                        <div>
                            <form action="/mail.php" method= "POST" className="mt-2">
                                <div className='mt-3'>
                                    <label className="block mb-1  ">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder='Enter Name'
                                    />

                                </div>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4  mt-3'>
                                    <div>
                                        <label className="block mb-1">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="w-full p-2 border rounded-md"
                                            placeholder='Enter Email'

                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-1   ">Phone</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            className="w-full p-2 border rounded-md"
                                            placeholder='Enter Phone'

                                        />
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <label className="block mb-1 ">Message</label>
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder='Enter Message'

                                    ></textarea>
                                </div>

                                <div className='mt-3'>

                                    <button className="btn btn-primary" 
                                        onClick={handleSubmit} type='submit'> Submit</button>
                                </div>
                            </form>
                        </div>






                    </div>

                    <div>

                    </div>
                </div>
            </section>
            <Footer />
        </section>
    )
}
