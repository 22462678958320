// Modal.js
import React from 'react';

const Modal = ({ isVisible, onClose, children }) => {

  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id == "wrapper") onClose();
  }

  return (
    <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center' onClick={handleClose} id="wrapper">
      <div className='w-[900px]  flex flex-col h-auto' >
        <button className='text-black text-3xl place-self-end close-btn' onClick={() => onClose()}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
        </button>
        <div>{children} </div>

      </div>

    </div>
  );
};

export default Modal;
