import React, { useState, useEffect, useRef } from "react";
import {
  easeInOut,
  motion,
  useS,
  useScroll,
  useTransform,
  useSpring,
  useInView,
} from "framer-motion";
import Lottie from "lottie-react";
import Slider from "react-slick";

import axios from "axios";

import { Footer } from "../components/Footer";
import Modal from "../components/modal";
import { ProjectCard } from "../components/ProjectCard";
import ModalBook from "../components/ModalBook";
import BrandLogos from "../components/BrandLogos";
import scroll from "../lottiefiles/scroll.json";
import { Loader } from "../components/loader";
import { VideoCard } from "../components/VideoCard";
import SmallLoader from "../components/SmallLoader";
import testi from "../images/testimonial.png";

import "./pages.css";

import bgImg from "../images/bg-video.mp4";
import virtualBgOne from "../images/bg-1.png";
import virtualBgTwo from "../images//bg-2.png";
import virtualBgCircle from "../images/bg-3.png";
import ImmersiveExperienceIcon from "../images/icon/icon-3.png";
import InteractiveExplorationIcon from "../images/icon/icon-1.png";
import DegreeViewIcon from "../images/icon/icon-2.png";
import AnimatedVideoImg from "../images/services/2.png";
import ResidentialImg from "../images/services/3.png";
import PhotoShootImg from "../images/services/1.png";
import RealEstateOne from "../images/realestate/real-1.png";
import RealEstateTwo from "../images/realestate/real-2.png";

import HospitalityOne from "../images/hospitality/hospitality-2.png";
import HospitalityTwo from "../images/hospitality/hospitality-1.png";

import VisualExperienceOne from "../images/Virtual/virtual-1.png";
import VisualExperienceTwo from "../images/Virtual/virtual-2.png";

import iconArrow from "../images/icon/arrow-up.png";
import iconVision from "../images/icon/vision.png";
import iconMission from "../images/icon/mission.png";

import VirtualMagic from "../images/Best-stories.png";
import play from "../images/icon/Video_fill.png";
import videoImg from "../images/testimonial.png";
import WpImg from "../images/icon/whatsApp.gif";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useHistory } from "react-router-dom";
import ScrollToTopSections from "./scroll";

const localPath = process.env.REACT_APP_API_KEY;

const fadInSlowAnimationVariants = {
  initial: {
    x: -10,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    duration: 5,
    timeConstant: easeInOut,
  },
};

export const Home = () => {
  const [displayText, setDisplayText] = useState("");
  const [walkthroughdItem, setWalkthroughdItem] = useState([]);
  const [VideoItem, setVideoItem] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showBookDemo, setShowBookDemo] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [isAutoplaying, setIsAutoplaying] = useState(false);
  const [isBuffered, setIsBuffered] = useState(true);
  const [loaders, setLoaders] = useState(false);
  const [live, setLive] = useState([]);
  const [phAn, setPhAn] = useState([]);

  // const handleBuffered = () => {
  //   setIsBuffered(true);
  // };

  console.log("phAn",phAn)

  const settings = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768, // tablet breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },

      {
        breakpoint: 480, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  const videoSlide = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 480, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const projects = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // tablet breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 480, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const info = [
    { id: 1, projectLabel: "projectLabel1" },
    { id: 2, projectLabel: "projectLabel2" },
    { id: 6, projectLabel: "projectLabel3" },
    { id: 3, projectLabel: "projectLabel4" },
    { id: 7, projectLabel: "projectLabel5" },
    { id: 4, projectLabel: "projectLabel6" },
    { id: 8, projectLabel: "projectLabel7" },
    { id: 5, projectLabel: "projectLabel8" },
  ];
  const textToType = "Redefining Spaces, Elevating Experiences.";

  useEffect(() => {
    fetchTestimonials();
    fetchData();

    const hash = window.location.hash;

    if (hash == "#Estat") {
      const element = document.querySelector(hash);
      element.scrollIntoView({ behavior: "smooth" });
    } else if (hash == "Hospitality") {
      const element = document.querySelector(hash);
      element.scrollIntoView({ behavior: "smooth" });
    } else if (hash == "Clients") {
      const element = document.querySelector(hash);
      element.scrollIntoView({ behavior: "smooth" });
    }
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= textToType.length) {
        setDisplayText(textToType.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100); // Adjust typing speed here (milliseconds)

    return () => clearInterval(typingInterval);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://doorto360.com/php/api.php");
      const filterLive = response?.data?.filter(
        (item) => item.option_dl == "Live"
      );

      const PhAn = filterLive.filter(
        (item) => item.service_id === "3" || item.service_id === "4" ||item.service_id === "1" || item.service_id === "2"      );
        const PhAnData =  PhAn.reverse()
      setPhAn(PhAnData.slice(0, 8));
      const JSONDataLive = JSON.stringify(filterLive);
      localStorage.setItem("LiveData", JSONDataLive);
      setLive(JSON.parse(localStorage.getItem("LiveData")));
    } catch (error) {
    } finally {
    }
  };

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(
        "https://doorto360.com/php/testimonial_api.php"
      );
      const TestimonialsData = response.data;
      setTestimonials(TestimonialsData);
      setLoaders(true);
    } catch (error) {
    } finally {
    }
  };

  const videoModalHandler = (data) => {
    setShowModal(true);
    setSelectedData(data);
  };

  const BookDemoHandler = () => {
    setShowBookDemo(true);
  };
  const ref = useRef(null);

  const isInView = useInView(ref, { amount: 0.5 });

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });

  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);
  const OpacityProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);

  const text = "Virtual Reality Tours".split(" ");
  const textVr =
    " Step into a realm where imagination meets reality with our Virtual Reality Tours. Immerse yourself in lifelike environments, where every detail comes to life in stunning 3D.Whether you're exploring a luxurious property or a captivating hospitality venue, our VR Tours transport you to the heart of the experience. Feel the warmth of sunlight streaming through the windows, hear the gentle rustle of leaves in the breeze, and envision yourself in space like never before. With Doorto360, the future of exploration is at your fingertips, offering a seamless blend of innovation and realism that will leave you breathless.";

  const refRe = useRef(null);
  const isInViewRe = useInView(refRe, { amount: 0.5 });

  const textRE = "Real Estate".split(" ");
  const textRe =
    "Transform your real estate listings into immersive experiences that captivate potential buyers.. From sprawling estates to cozy urban a partments, our Virtual Tours and Animated Video Walkthroughs bring each listing to life, highlighting every unique feature and detail. With Doorto360, sellers can attract more qualified leads, while buyers can explore properties from the comfort of their own homes, making the search for the perfect space an unforgettable experience.";

  const refH = useRef(null);
  const isInViewH = useInView(refH, { amount: 0.5 });

  const textH = "Hospitality".split(" ");
  const textHp =
    "  Indulge in the art of hospitality with Doorto360. Our immersive solutions transform ordinary venues into extraordinary destinations, crafting unforgettable experiences for your guests. Whether it's a boutique hotel, scenic resort, or charming restaurant, our VR experiences, informative 360 walkthroughs, and expert video and photo shoots create virtual journeys that captivate and enchant your audience. With  stunning visuals and interactive features, we help you create memorable moments that ensure guests return for more delightful experiences.";

  const refVs = useRef(null);
  const isInViewVs = useInView(refVs, { amount: 0.5 });

  const textV = "Virtual The Visual Experience".split(" ");
  const textVp =
    "  At Doorto360, we're driven by a passion for innovation and a commitment to excellence. For the past 2 years, we've been at the forefront of redefining the space selling experience. We aim to bridge the gap between imagination and reality, creating virtual environments that captivate and inspire. With every project, we aim to bring tangible value to your business and services, turning dreams into virtual realities.";

  return (
    <>
      {!isBuffered && <Loader></Loader>}

      <div className="home">
        <section className="banner">
          <div className="video-container">
            <div className="black-img"></div>
            {/* <video
              id="videoPlayer"
              loop
              muted
              className="object-cover h-lvh w-lvw"
              autoplay="true"
              onCanPlayThrough={handleBuffered}
              playsinline="true"
              preload="auto"
            >
              <source src={bgImg} type="video/mp4" />
            </video> */}
            <div class="video-background">
              <div
                className="video-foreground"
                style={{
                  padding: " 0 0 39.58%  0",
                  position: "relative",
                
                  overflow: "hidden",
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/940611048?badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479&amp;controls=0&amp;muted=1&amp;loop=1"
                  width="1920"
                  height="1080"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  style={{ position: "absolute", top: 0, left: 0 }}
                  title="SHOWREEL"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
            <div className="black-overlay"></div>
          </div>

          <div className="container px-4 bg-body max-auto lg:px-20">
            <div className="grid grid-flow-col gap-4 lg:grid-cols-2 sm:gird-cols-1">
              <div className="row-span-2">
                <motion.h1
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  {displayText}
                </motion.h1>
                <p className="text-skin-base">
                  Explore and envision your spaces like never before with
                  Doorto360's Immersive VR Tours.
                </p>
                <div>
                  <button
                    onClick={BookDemoHandler}
                    className="mt-4 btn btn-primary"
                  >
                    Book A Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="scroll-icon-set">
            <small className="textVertical"> Scroll Down</small>
            <div className="vertical-line"></div>
            <div className="scroll-icon">
              <Lottie animationData={scroll} loop={true}></Lottie>
            </div>
          </div>
          <motion.a
            className="wapp"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            href="https://wa.me/9778305495"
            target="_blank"
          >
            <img src={WpImg} alt="" loading="la" />
          </motion.a>
        </section>

        <section className="px-4 vr-service services p-t-b lg:px-20">
          <div className="grid items-center md:grid-cols-2">
            <motion.div
              ref={ref}
              style={{ scale: scaleProgress, opacity: OpacityProgress }}
              className="relative flex services-imgs"
            >
              <motion.img
                initial={{ y: 40 }}
                whileInView={{ y: 0 }}
                transition={{ duration: 2, ease: easeInOut }}
                src={virtualBgCircle}
                alt=""
                className="absolute service-bg"
              />
              <motion.img
                variants={fadInSlowAnimationVariants}
                initial="initial"
                whileInView={{ x: 0 }}
                transition={{ duration: 1.5, timeConstant: easeInOut }}
                src={virtualBgOne}
                alt=""
                className=""
              />
              <motion.img
                initial={{ x: -60 }}
                whileInView={{ x: 0 }}
                transition={{ duration: 2, timeConstant: easeInOut }}
                src={virtualBgTwo}
                alt=""
                className="absolute service-circle"
              />
            </motion.div>
            <div>
              <div className="flex flex-col gap-5">
                <div>
                  <small className="small-text">Services</small>
                  <i class="fa-solid fa-magnifying-glass"></i>

                  <h3 className="text-4xl text-gray-900">
                    {text.map((s, i) => (
                      <motion.span
                        ref={ref}
                        initial={{ opacity: 0 }}
                        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{
                          duration: 1,
                          delay: i / 6,
                        }}
                        key={i}
                      >
                        {s}{" "}
                      </motion.span>
                    ))}
                  </h3>
                </div>
                <p>
                  <motion.span
                    ref={ref}
                    initial={{ opacity: 0 }}
                    animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                    transition={{
                      duration: 0.2,
                      delay: 1 / 4,
                    }}
                  >
                    {textVr}
                  </motion.span>
                </p>
                <div>
                  <NavLink
                    to="/Services#VirtualReality"
                    className="mt-4 btn btn-secondary md:mt-5"
                  >
                    Know More
                  </NavLink>
                </div>
              </div>

              <div className="flex mt-5 sm:gap-6 lg:gap-32 md:pt-8 md:px-3">
                <div
                  variants={fadInSlowAnimationVariants}
                  initial="initial"
                  whileInView={{ x: 0 }}
                  transition={{ duration: 1.5, timeConstant: easeInOut }}
                  className="flex flex-col items-center "
                >
                  <img src={ImmersiveExperienceIcon} alt="" className="w-20" />
                  <h6 className="text-base font-bold md:w-16 text-label">
                    Immersive Experience
                  </h6>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={InteractiveExplorationIcon}
                    alt=""
                    className="w-20"
                  />
                  <h6 className="text-base font-bold md:w-16 text-label ">
                    Interactive Exploration
                  </h6>
                </div>
                <div className="flex flex-col items-center">
                  <img src={DegreeViewIcon} alt="" className="w-20" />
                  <h6 className="text-base font-bold md:w-18 text-label">
                    360 Degree View
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section></section>
        <section className=" all-service lg:px-20">
          <div className="grid items-center md:grid-cols-1">
            <Slider {...settings}>
              <div>
                <div className="card-service border-right">
                  <motion.img
                    variants={fadInSlowAnimationVariants}
                    initial={{ scale: 0.9 }}
                    whileInView={{ scale: 1 }}
                    transition={{ duration: 2, timeConstant: easeInOut }}
                    src={ResidentialImg}
                    alt=""
                    className="w-full"
                  />
                  <div>
                    <small className="small-text">Services</small>
                    <h4 className="card-head">360 Walkthroughs</h4>
                  </div>
                  <p>
                    Discover the power of interactive 3D virtual tours for your
                    properties! With superimposed renderings and accessibility
                    from anywhere, anytime, potential buyers can now explore
                    your listings comprehensively. They can walk through rooms,
                    scrutinize layouts, floor plans, and fine details
                    conveniently, gaining a thorough understanding of your space
                    and increasing their interest in your property.
                  </p>
                  <div>
                    <NavLink
                      to="/Services#PhotoShoot"
                      className="btn btn-secondary"
                    >
                      Know More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div>
                <div className="card-service border-right">
                  <motion.img
                    variants={fadInSlowAnimationVariants}
                    initial={{ scale: 0.9 }}
                    whileInView={{ scale: 1 }}
                    transition={{ duration: 2, timeConstant: easeInOut }}
                    src={PhotoShootImg}
                    alt=""
                    className="w-full"
                  />
                  <div>
                    <small className="small-text">Services</small>
                    <h4 className="card-head">Animated Video</h4>
                  </div>
                  <p>
                    Whether it's showcasing the flow of a floor plan or
                    highlighting key features, our animated walkthroughs breathe
                    personality into every frame.With seamless transitions and
                    attention to detail, we breathe new life into your spaces,
                    leaving a lasting impression on potential buyers and guests.
                  </p>
                  <div>
                    <NavLink
                      to="/Services#AnimatedVideos"
                      className="btn btn-secondary"
                    >
                      Know More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div>
                <div className="card-service border-right ">
                  <motion.img
                    variants={fadInSlowAnimationVariants}
                    initial={{ scale: 0.9 }}
                    whileInView={{ scale: 1 }}
                    transition={{ duration: 2, timeConstant: easeInOut }}
                    src={AnimatedVideoImg}
                    alt=""
                    className="w-full"
                  />
                  <div>
                    <small className="small-text">Services</small>
                    <h4 className="card-head">Video & Photo Shoot</h4>
                  </div>
                  <p>
                    Experience seamless visual storytelling with our
                    comprehensive photography and videography services. Whether
                    selling a home or promoting a business, we blend real estate
                    photography with cinematic magic. From breathtaking aerial
                    views to intimate close-ups, we capture the essence of your
                    property, fostering engagement and creating a memorable
                    impact.
                  </p>
                  <div>
                    <NavLink
                      to="/Services#VideoShoot"
                      className="btn btn-secondary"
                    >
                      Know More
                    </NavLink>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        <section className="px-4 real-estate-service p-t-b lg:px-20" id="Estat">
          <div className="grid items-center md:grid-cols-2">
            <div>
              <div className="flex flex-col gap-5 lg:gap-4">
                <div>
                  <small className="small-text">Sectors</small>
                  <h3 className="text-4x real-estate-head">
                    {textRE.map((s, i) => (
                      <motion.span
                        ref={refRe}
                        initial={{ opacity: 0 }}
                        animate={isInViewRe ? { opacity: 1 } : { opacity: 0 }}
                        transition={{
                          duration: 0.1,
                          delay: i / 6,
                        }}
                        key={i}
                      >
                        {s}{" "}
                      </motion.span>
                    ))}
                  </h3>
                </div>
                <p>
                  <motion.span
                    ref={refRe}
                    initial={{ opacity: 0 }}
                    animate={isInViewRe ? { opacity: 1 } : { opacity: 0 }}
                    transition={{
                      duration: 0.2,
                      delay: 1 / 4,
                    }}
                  >
                    {textRe}
                  </motion.span>
                </p>
                <div>
                  <button
                    onClick={BookDemoHandler}
                    className="mt-4 btn btn-primary"
                  >
                    Book A Demo
                  </button>
                </div>
              </div>
            </div>
            <div className="relative flex items-end mt-4 services-imgs md:mt-0">
              <motion.img
                variants={fadInSlowAnimationVariants}
                initial="initial"
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.5, timeConstant: easeInOut }}
                src={RealEstateOne}
                alt=""
                className=""
              />
              <motion.img
                initial={{ opacity: 0, y: -60 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5, timeConstant: easeInOut }}
                src={RealEstateTwo}
                alt=""
                className="absolute service-bg"
              />
            </div>
          </div>
        </section>
        <section
          className="px-4 hospitality-service p-t-b lg:px-20"
          id="Hospitality"
        >
          <div className="grid items-center md:grid-cols-2 ">
            <div className="relative flex services-imgs">
              <motion.img
                initial={{ y: 40 }}
                whileInView={{ y: 0 }}
                transition={{ duration: 1.5, timeConstant: easeInOut }}
                src={HospitalityOne}
                alt=""
                className=""
              />
              <motion.img
                variants={fadInSlowAnimationVariants}
                initial="initial"
                whileInView={{ x: 0 }}
                transition={{ duration: 1.5, timeConstant: easeInOut }}
                src={HospitalityTwo}
                alt=""
                className="absolute service-bg"
              />
            </div>

            <div>
              <div className="flex flex-col gap-5 lg:gap-4">
                <div>
                  <small className="small-text">Sectors</small>
                  <h3 className="text-4x service-head ">
                    {textH.map((s, i) => (
                      <motion.span
                        ref={refH}
                        initial={{ opacity: 0 }}
                        animate={isInViewH ? { opacity: 1 } : { opacity: 0 }}
                        transition={{
                          duration: 0.1,
                          delay: i / 2,
                        }}
                        key={i}
                      >
                        {s}{" "}
                      </motion.span>
                    ))}
                  </h3>
                </div>
                <p>
                  <motion.span
                    ref={refH}
                    initial={{ opacity: 0 }}
                    animate={isInViewH ? { opacity: 1 } : { opacity: 0 }}
                    transition={{
                      duration: 0.2,
                      delay: 1 / 4,
                    }}
                  >
                    {textHp}
                  </motion.span>
                </p>
                <div>
                  <button
                    onClick={BookDemoHandler}
                    className="mt-4 btn btn-primary"
                  >
                    Book A Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="px-4 Visual-Experience-service p-t-b lg:px-20">
          <div className="grid items-center md:grid-cols-2">
            <div>
              <div className="flex flex-col gap-5 lg:gap-4">
                <div>
                  <small className="small-text">About Us</small>
                  <h3 className="text-4x service-head">
                    {textV.map((s, i) => (
                      <motion.span
                        ref={refVs}
                        initial={{ opacity: 0 }}
                        animate={isInViewVs ? { opacity: 1 } : { opacity: 0 }}
                        transition={{
                          duration: 0.1,
                          delay: i / 10,
                        }}
                        key={i}
                      >
                        {s}{" "}
                      </motion.span>
                    ))}
                  </h3>
                </div>
                <p>
                  <motion.span
                    ref={refVs}
                    initial={{ opacity: 0 }}
                    animate={isInViewVs ? { opacity: 1 } : { opacity: 0 }}
                    transition={{
                      duration: 0.2,
                      delay: 1 / 4,
                    }}
                  >
                    {textVp}
                  </motion.span>
                </p>
                <div>
                  <button
                    onClick={BookDemoHandler}
                    className="mt-4 btn btn-primary"
                  >
                    Book A Demo
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-3 pt-6 lg:grid-cols-2 lg:gap-3 md:mt-4">
                <div className="md:mr-8 ">
                  <div className="flex items-center gap-4 mb-2">
                    <img className="icon-vr" src={iconVision} alt="" />
                    <h3 className="blod-text">Vision</h3>
                  </div>
                  <p>
                    To transform the space selling experience, providing
                    sophisticated virtual tours and immersive experiences across
                    sectors. 
                  </p>
                </div>
                <div className="mt-2 lg:mt-0">
                  <div className="flex items-center gap-4 mb-2">
                    <img className="icon-vr" src={iconMission} alt="" />
                    <h3 className="blod-text">Mission</h3>
                  </div>
                  <p>
                    We envision a future where virtual reality blurs the lines
                    between imagination and reality, empowering businesses to
                    reach the full potential for their spaces.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative flex items-end mt-5 services-imgs lg:mt-0 ">
              <motion.img
                variants={fadInSlowAnimationVariants}
                initial="initial"
                whileInView={{ x: 0 }}
                transition={{ duration: 1.5, timeConstant: easeInOut }}
                src={VisualExperienceOne}
                alt=""
                className=""
              />
              <motion.img
                initial={{ y: 60 }}
                whileInView={{ y: 0 }}
                transition={{ duration: 2, timeConstant: easeInOut }}
                src={VisualExperienceTwo}
                alt=""
                className="absolute service-bg"
              />
            </div>
          </div>
        </section>
        {phAn?.length === 0 ? (
          <></>
        ) : (
          <section className="px-4 p-t-b explore-bg lg:px-20">
            <div className="grid items-center text-center md:grid-cols-1">
              <small className="small-text color-text">Our Best Stories</small>
              <h3 className="mb-3 text-center text-4x service-head">
                Explore Our Virtual Magic
              </h3>

              <ProjectCard cardInfo={phAn} info="walkthrough"></ProjectCard>

              <div className="flex justify-center mt-4">
                <NavLink to="/Projects" className="btn btn-secondary">
                  View more
                </NavLink>
              </div>
            </div>
          </section>
        )}

        <section className="px-4 dark-bg p-t-b lg:px-20">
          <BrandLogos id="Clients"></BrandLogos>
        </section>

        <section className="px-4 partnership bg lg:px-20 p-t-b">
          <div className="grid items-center text-center md:grid-cols-1">
            <small className="text-center small-text color-text">
              What our clients says
            </small>
            <h1 className="text-center text-4x service-head lg:mb-4">
              Stories of Successful Partnership
            </h1>
            {loaders == false ? (
              <>
                <SmallLoader />
              </>
            ) : (
              // <Slider {...videoSlide} className="mt-4 lg:mt-0">
              //   {testimonials.map((items) => (
              //     <VideoCard dataT={items} />
              //   ))}
              // </Slider>
              <Slider {...videoSlide} className="mt-4 lg:mt-0">
                {testimonials?.map((item) => (
                  <motion.div
                    initial={{ y: 30 }}
                    whileInView={{ y: 0 }}
                    transition={{ duration: 1.5, ease: easeInOut }}
                  >
                    <div className="img-testimonial">
                      <button
                        className="btn"
                        onClick={() => videoModalHandler(item.link)}
                      >
                        <img className="testimonial-img" src={testi} alt="" />
                        <img className="play" src={play} alt="" />
                      </button>
                    </div>
                  </motion.div>
                ))}
              </Slider>
            )}

            <div className="mt-4 text-center md:mt-8 ">
              <div className="flex justify-center">
                <NavLink to="/Projects" className="btn btn-secondary">
                  View more
                </NavLink>
              </div>
            </div>
          </div>
        </section>

        <div className="container max-auto "></div>

        <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
          <div className="p-">
            <iframe
              width="100%"
              height="500"
              src={selectedData}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </Modal>
        <ModalBook
          isVisible={showBookDemo}
          onClose={() => setShowBookDemo(false)}
        />

        <Footer />
      </div>
    </>
  );
};
