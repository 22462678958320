import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";


import { Footer } from '../components/Footer';

import "./pages.css";

import bgImg from '../images/bg-privacy.mp4';
import phone from '../images/icon/phone-call.png';
import mail from '../images/icon/mail.png';
import marker from '../images/icon/marker.png'



export const Privacy = () => {
    const textToType = 'Privacy Policy.';
    const [displayText, setDisplayText] = useState('');


 
    useEffect(() => {
        let currentIndex = 0;
        const typingInterval = setInterval(() => {
            if (currentIndex <= textToType.length) {
                setDisplayText(textToType.slice(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(typingInterval);
            }
        }, 100); // Adjust typing speed here (milliseconds)

        return () => clearInterval(typingInterval);
    }, []);
    return (
        <section className='contact-page' id='Contact'>
            <section className='banner'>
                <div className="video-container  sub-banner-video">
                    <video autoplay loop muted className="object-cover" autoPlay>
                        <source src={bgImg} type="video/mp4" />
                    </video>
                    <div className='black-overlay'></div>
                </div>
                <div className='bg-body container max-auto px-4 lg:px-20  width-max'>
                    <div className="grid lg:grid-cols-1 gird-cols-1 grid-flow-col text-center">
                        <div className="row-span-2">
                            <motion.h1
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1 }}
                            >
                                {displayText}
                            </motion.h1>

                        </div>
                    </div>
                </div>
            </section>
            <div className="services-vr">
            <section className='p-t-b px-4 lg:p-20'>
                <div className='grid grid-cols-12 services-grid lg:p-10'>
                    <div className='col-span-12'>
                    <h1 className='mb-5'>Privacy Policy for Doorto360</h1>
                    <p>At Doorto360, accessible from [www.doorto360.com] we are committed to protecting your privacy. This Privacy Policy outlines the types of personal information we collect, how we use it, and your rights regarding that information.</p>
                        <p className='mt-6'>
                            <h3 className='text-xl font-bold mb-3'>1. Information We Collect</h3>
We may collect personal information from you when you visit our website, register on our site, subscribe to our newsletter, or interact with our services. The information we collect may include:
<ul className='mt-3 list-disc list-inside'>
    <li>Personal Identification Information: Name, email address, phone number, and other contact details.</li>
    <li>Technical Data: IP address, browser type, time zone setting, browser plug-in types, operating system, and platform.</li>
    <li>Usage Data: Information about how you use our website, products, and services.
    </li>
    <li>Marketing and Communications Data: Your preferences in receiving marketing from us and your communication preferences.
    </li>
</ul>
                        </p>
                        <p className='mt-6'>
                            <h3 className='text-xl font-bold mb-3'>2. How We Use Your Information</h3>
                            We use the information we collect for various purposes, including:
<ul className='mt-3 list-disc list-inside'>
    <li>To provide, maintain, and improve our website and services.</li>
    <li>To communicate with you, including responding to your comments, questions, and requests.
    </li>
    <li>To send you newsletters, marketing materials, and other information that may be of interest to you.
    </li>
    <li>To analyze usage and trends to improve your experience with our website.
    </li>
    <li>To detect, prevent, and address technical issues.
    </li>
</ul>
</p>

<p className='mt-6'>
                            <h3 className='text-xl font-bold mb-3'>3. Sharing Your Information</h3>
                            We do not sell, trade, or otherwise transfer your personal information to outside parties except:
<ul className='mt-3 list-disc list-inside'>
    <li>To trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</li>
    <li>When we believe release is appropriate to comply with the law, enforce our site policies, or protect our rights or others' rights, property, or safety.
    </li>

</ul>
</p>
<p className='mt-6'>
                            <h3 className='text-xl font-bold mb-3'>4. Data Security
                            </h3>
                            We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
</p>
<p className='mt-6'>
                            <h3 className='text-xl font-bold mb-3'>5. Your Rights</h3>
                            Depending on your location, you may have the following rights regarding your personal information:
<ul className='mt-3 list-disc list-inside'>
    <li>The right to access  You have the right to request copies of your personal information.</li>
    <li>The right to rectification  You have the right to request that we correct any information you believe is inaccurate or incomplete.
    </li>
        <li>The right to erasure  You have the right to request that we erase your personal information, under certain conditions.</li>
<li> The right to restrict processing  You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
<li>The right to data portability  You have the right to request that we transfer the data we have collected to another organization, or directly to you, under certain conditions.</li>

</ul>
</p>
<p className='mt-6'>
                            <h3 className='text-xl font-bold mb-3'>6. Cookies and Tracking Technologies
                            </h3>
                            We use cookies and similar tracking technologies to monitor activity on our website and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.
</p>
<p className='mt-6'>
                            <h3 className='text-xl font-bold mb-3'>7. Changes to This Privacy Policy
                            </h3>
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
</p>
<p className='mt-6'>
                            <h3 className='text-xl font-bold mb-3'>8. Contact Us
                            </h3>
                            If you have any questions about this Privacy Policy, please contact us:
                            <ul className='mt-3 list-disc list-inside'>
                                <li>By email: contact@doorto360.com</li>
                                <li>+91 9778305495</li>
                            </ul>

</p>
                    </div>
                        
              </div>
            </section>
            </div>
            <Footer />
        </section>
    )
}
