import React from 'react';
import './smallLoader.css'

const SmallLoader = () => {
  return (
    <div>
      <div id="loading-sm"></div>
    </div>
  )
}

export default SmallLoader
