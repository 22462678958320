import React from "react";
import Lottie from "lottie-react";
import loading from "../lottiefiles/loading.json";
import logo from "../images/logo.png";

export const Loader = () => {
  return (
    <div className="loader">
       <div className="flex flex-col-reverse justify-center text-center items-center relative">
       <p className="text-data">Loading</p>
            <div className="loader-icon">
              <Lottie animationData={loading} loop={true}></Lottie>
            </div>
                       


            <img src={logo} />
            

          </div>
    
    </div>
  );
};
