// require('../images/client-logo.png'),
// require('../images/client-logo.png'),
// require('../images/client-logo.png'),
// // LogoGrid.js
import React, { useEffect, useState } from 'react';

// import clientLogos from './clientLogos';
const logos = [
    require('../images/logos/aroma.png'),
    require('../images/logos/artech.png'),
    require('../images/logos/confident.png'),
    require('../images/logos/cordial-homes.png'),
    require('../images/logos/cordon.png'),
    // require('../images/logos/green-vistas.png'),
    require('../images/logos/kalyan.png'),
    require('../images/logos/malabar.png'),
    require('../images/logos/maruthuam.png'),
    require('../images/logos/olive.png'),
    // require('../images/logos/prestige.png'),
    require('../images/logos/varma-homes.png'),
    require('../images/logos/veegaland.png'),
    require('../images/logos/cordial-prop.png'),


    // Add more logo paths as needed
];

const BrandLogos = () => {
    const [shiftIndex, setShiftIndex] = useState(0);
    const [isSwitchedOn, setSwitchedOn] = useState(false);
    const [logoOrder, setLogoOrder] = useState([...logos]);
  const [animationEnabled, setAnimationEnabled] = useState(true);





    useEffect(() => {
        const intervalId = setInterval(() => {
            setShiftIndex((prevIndex) => (prevIndex + 1) % logos.length);
            setSwitchedOn(!isSwitchedOn);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [logos]);

    useEffect(() => {
        const interval = setInterval(() => {
          setAnimationEnabled((prev) => !prev);
          setLogoOrder((prevOrder) => {
            const newOrder = [...prevOrder];
            const movedLogo = newOrder.pop();
            newOrder.unshift(movedLogo);
            return newOrder;
          });
        }, 4000);
    
        return () => clearInterval(interval);
      }, [logos]);

    return (
        <div className="grid lg:grid-cols-6 grid-cols-3 gap-5 client">
            {logoOrder.map((logo, index) => (
                <div className={`transition-transform transform ${animationEnabled ? 'duration-300' : ''}`}
                style={{ order: index + 1 }}> 
                    <div
                        key={index}
                    >
                        <img  src={logo} alt={`Client Logo ${index + 1}`} className={`client-icon ${index === shiftIndex ? 'filterCssFull' : 'filterCssOp'
                            }`} />
                    </div>
                </div>
            ))}
        </div>
    );
};


export default BrandLogos;