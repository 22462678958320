import React, { useState, useEffect } from "react";
import {
  easeInOut,
  motion,
  useS,
  useScroll,
  useTransform,
  useSpring,
} from "framer-motion";
import iconArrow from "../images/icon/arrow-up.png";
import Modal from "../components/modal";
import play from "../images/icon/Video_fill.png";

import VirtualMagic from "../images/Best-stories.png";

const localPath = process.env.REACT_APP_API_KEY;

export const ProjectCard = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const videoModalHandler = (data) => {
    setShowModal(true);
    setSelectedData(data);
  };

  return (
    <div>
          <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        <div className="p-">
          <iframe
            width="100%"
            height="500"
            src={selectedData}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </Modal>
      <div>
      <div className="grid grid-cols-1 gap-2 mt-3 md:grid-cols-4 lg:gap-8">
            {props?.cardInfo?.map((item) => (
              <div className="experience">
                <div className="experience-img">
                  <motion.img
                    initial={{ y: 30 }}
                    whileInView={{ y: 0 }}
                    transition={{ duration: 2, timeConstant: easeInOut }}
                    src={localPath + item?.image}
                    alt=""
                    className="w-full"
                  />
                </div>
                <div className="experience-card">
                  <div className="flex items-center justify-between px-4 lg:px-4">
                    <div>
                   
                      <h1 className="text-xl explore-head">{item?.title}</h1>
                    </div>
                    <div>
                     { item?.service_id =="2" ?  <a href={item?.link} target="_blank"
                        className="p-1 rounded-full btn-secondary"
                      >                        <img src={play} alt="" />
</a> :<button
                        className="p-1 rounded-full btn-secondary"
                        onClick={() => videoModalHandler(item?.link)}
                      >
                        <img src={play} alt="" />
                      </button>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
      </div>
    </div>
  );
};
