import React, { useState, useEffect } from "react";
import { easeInOut, motion } from "framer-motion";
import axios from "axios";

import Modal from "../components/modal";
import { VideoCard } from "../components/VideoCard";

import { NavBar } from "../components/navBar";
import { Footer } from "../components/Footer";
import { ProjectCard } from "../components/ProjectCard";
import "./pages.css";
import play from "../images/icon/Video_fill.png";
import { VideoCardTest } from "../components/VideoCardTest";
import bgImg from "../images/bg-services.mp4";

const localPath = process.env.REACT_APP_API_KEY;

export const Projects = () => {
  const textToType = "Explore our works";
  const [displayText, setDisplayText] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [walkthroughdItem, setWalkthroughdItem] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [VideoItem, setVideoItem] = useState([]);
  const [VideoItemPH, setVideoPHItem] = useState([]);
  const [VideoItemAN, setVideoANItem] = useState([]);
  const [VideoItemVS, setVideoVSItem] = useState([]);
  const [datavr, setDataVr] = useState([]);

  useEffect(() => {
    const LiveData = localStorage?.getItem("LiveData");

    const LiveDatas = JSON?.parse(LiveData);
    setDataVr(LiveDatas?.filter((item) => item.service_id == 1).reverse());
    setVideoPHItem(LiveDatas?.filter((item) => item.service_id == 2).reverse());
    setVideoANItem(LiveDatas?.filter((item) => item.service_id == 4).reverse());
    setVideoVSItem(LiveDatas?.filter((item) => item.service_id == 3).reverse());
  }, []);

  console.log("datavr", datavr);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= textToType?.length) {
        setDisplayText(textToType.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100); // Adjust typing speed here (milliseconds)

    return () => clearInterval(typingInterval);
  }, []);
  const videoModalHandler = (data) => {
    setShowModal(true);
    setSelectedData(data);
  };

  return (
    <section className="project-page">
      <section className="banner">
        <div className="video-container sub-banner-video">
          <video autoplay loop muted className="object-cover" autoPlay>
            <source src={bgImg} type="video/mp4" />
          </video>
          <div className="black-overlay"></div>
        </div>
        <div className="container px-4 bg-body max-auto lg:px-20 width-max">
          <div className="grid grid-flow-col text-center lg:grid-cols-1 gird-cols-1">
            <div className="row-span-2">
              <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                {displayText}
              </motion.h1>
            </div>
          </div>
        </div>
      </section>
      <section className="px-4 p-t-b lg:px-20">
        {/* <Tab tabs={tabs} /> */}
        {/* Your tab content goes here */}
        <div>
          <div className="tab-container">
            <button
              className={`px-4 py-2 sub-tab  ${
                activeTab === 1 ? "active-tab" : ""
              }`}
              onClick={() => handleTabClick(1)}
            >
              <span className="flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                  />
                </svg>
                Virtual Reality
              </span>
            </button>
            <button
              className={`px-4 py-2 sub-tab ${
                activeTab === 2 ? "active-tab" : ""
              }`}
              onClick={() => handleTabClick(2)}
            >
              <span className="flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
                360 Walkthroughs
              </span>
            </button>
            <button
              className={`px-4 py-2 sub-tab ${
                activeTab === 3 ? "active-tab" : ""
              }`}
              onClick={() => handleTabClick(3)}
            >
              <span className="flex gap-2">
             
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                  />
                </svg>
                Animated Videos
              </span>
            </button>
            <button
              className={`px-4 py-2 sub-tab ${
                activeTab === 4 ? "active-tab" : ""
              }`}
              onClick={() => handleTabClick(4)}
            >
              <span className="flex gap-2">
             
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                  />
                </svg>
                Shoot
              </span>
            </button>
          </div>

          <div className="tab-content">
            {activeTab === 1 && (
              <>
             
                {datavr?.length === 0 ? (
                  <>Coming Soon</>
                ) : (
                   <ProjectCard cardInfo={datavr}></ProjectCard>
                )}
              </>
            )}

            {activeTab === 2 && (
              <>
             
                {VideoItemPH?.length === 0 ? (
                  <>Coming Soon</>
                ) : (
                  <ProjectCard cardInfo={VideoItemPH}></ProjectCard>
                )}
              </>
            )}
            {activeTab === 3 && (
              <>
             
                {VideoItemAN?.length === 0 ? (
                  <>Coming Soon</>
                ) : (
                  <ProjectCard cardInfo={VideoItemAN}></ProjectCard>
                )}
              </>
            )}
            {activeTab === 4 && (
              <>
             
                {VideoItemVS?.length === 0 ? (
                  <>Coming Soon</>
                ) : (
                  <ProjectCard cardInfo={VideoItemVS}></ProjectCard>
                )}
              </>
            )}
          </div>
        </div>
      </section>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        <div className="p-">
          <iframe
            width="100%"
            height="500"
            src={selectedData}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </Modal>

      <Footer />
    </section>
  );
};
