import React from 'react'



export const CardSimple = (props) => {
    return (
        <div className='simple-card ' >
            <div className='flex align-middle items-center gap-2 mb-2'>
                <img src={ `${props.img}`} alt="" className='card-img' />
                <h6 className='card-head'>
                    {props.Item.head}
                </h6>
            </div>
            <p> {props.Item.body}</p>
        </div>
    )
}
